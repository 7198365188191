<template>
  <div class="loading-wrapper p-d-flex p-ai-center p-jc-around bg-off-white p-py-6 p-my-auto">
    <div class="p-text-center">
      <p>Redirecting you to the application...</p>
    </div>
    <div>
      <h4 class="header p-error" v-if="backendError">{{ backendError }}</h4>
    </div>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";

export default {
  name: "LoginCallback",
  title: "LoginCallback",
  setup() {
    return { accountStore: useAccountStore() };
  },
  data() {
    return {
      backendError: null,
    };
  },
  computed: {
    redirect() {
      return this.$route.query.redirectUri;
    },
  },
  created() {
    if(this.accountStore.userLogged){
      this.redirectAfterSuccessfulLogin();
    }
    else{
      this.backendError = this.accountStore.error;
    }
  },
  methods: {
    redirectAfterSuccessfulLogin() {
      if (this.redirect) {
        // TODO LS GEN-487 this triggers a refresh which is inefficient
        // we can assure from the backend that the routes are local and just
        // push them to the router without the need of a redirect
        window.location = this.redirect;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
