<template>
  <div class="impersonate p-d-flex p-jc-between p-align-center p-mb-3">
    <div class="p-flex-row">
      <h6 class="p-m-0 p-text-uppercase p-text-bold">Impersonating</h6>
      <p class="p-mb-0 p-mt-2">{{ user?.impersonatedOriginalUser }} -> {{ user?.email }}</p>
    </div>
    <Button severity="secondary" @click.prevent="stopImpersonating">STOP</Button>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import Button from "primevue/button";
export default {
  name: "ImpersonatePopup",
  setup: () => {
    return { accountStore: useAccountStore() };
  },
  components: {
    Button,
  },
  computed: {
    user() {
      return this.accountStore.user;
    },
  },
  methods: {
    async stopImpersonating() {
      await this.accountStore.stopImpersonateUser();
    },
  },
};
</script>

<style scoped lang="scss">
.impersonate {
  width: 100%;
  background: var(--traffic-light-red);
  color: white;
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
  font-size: 1rem;
  padding: 1rem;
  align-self: center;
  z-index: 1;

  button {
    background-color: white;
    color: var(--traffic-light-red);
    border: none;
  }
}
</style>
