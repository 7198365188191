<template>
  <div class="loading-wrapper p-d-flex p-ai-center p-jc-around bg-off-white p-py-6 p-my-auto">
    <div class="p-text-center">
      <span class="pi pi-spinner pi-spin gradient-spinner" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  title: "Login",
  created() {
    const { domain_hint, login_hint, return_url } = this.$route.query;
    let params = [];
    let location = "/apptwo/account/login";

    if (domain_hint) {
      params.push(`domain_hint=${domain_hint}`);
    }

    if (login_hint) {
      params.push(`login_hint=${login_hint}`);
    }

    if (return_url) {
      params.push(`return_url=${return_url}`);
    }

    if (params.length > 0) {
      location = `/apptwo/account/login?${params.join("&")}`;
    }

    window.location.href = location;
  },
};
</script>
