<template>
  <div class="loading-wrapper p-d-flex p-ai-center p-jc-around bg-off-white p-py-6 p-my-auto">
    <div class="p-text-center">
      <span class="pi pi-spinner pi-spin gradient-spinner"/>
      <p>Logging you out...</p>
    </div>
  </div>
</template>
<script>
import { useAccountStore } from "@/stores/account";

export default {
  name: "Logout",
  title: "Logout",
  setup() {
    return { accountStore: useAccountStore() };
  },
  created() {
    this.accountStore.setUser({})
    window.location.href = "/apptwo/account/logout";
  },
};
</script>